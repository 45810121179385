
























































































































































































































































import { defineComponent, ref, onMounted, watch, Ref } from '@vue/composition-api'
import { api, moment, framebus } from 'plugins'
import {
  convertToWidthCharacter,
  endpoints,
  frameBusEvent,
  query,
  showError,
  toCamelCase,
  toSnakeCase
} from 'utils'
import { debounce, sortBy } from 'lodash'
import { AssignClaim, ClaimBase, Customer, Item, Variety } from 'typings'
import { ConfirmDelete, LoaderComponent } from 'components'
import ClaimRegisterDialog from './ClaimRegisterDialog.vue'
import ClaimHandlingDialog from './ClaimHandlingDialog.vue'

interface SearchItem {
  id: number
  name: string
  text: string
  category: string
}

interface Category {
  name: string
  icon: string
  data: Array<SearchItem>
}

interface CustomerClaim {
  customer: Customer
  claims: any[]
}

interface CustomerAssign {
  customer: Customer
  assigns: any[]
}

const ClaimRegister = defineComponent({
  components: { ClaimRegisterDialog, ClaimHandlingDialog, ConfirmDelete, LoaderComponent },
  setup(props, { root }) {
    const { $route, $store, $toast } = root
    const auctionDateModal = ref(false)
    const date = ref<string>(
      $store.state.common.latestSelectedDates.auctionDate
        ? $store.state.common.latestSelectedDates.auctionDate
        : $route.query.auctionDate || moment(new Date()).format('YYYY-MM-DD')
    )
    const selectedCustomerId = ref<any>(null)
    const filteredAssignList = ref<AssignClaim[]>([])
    const activeTab = ref(0)
    const claimRegisterDialogModal = ref(false)
    const claimHandlingDialogModal = ref(false)
    const countHandled = ref(0)
    const countUnhandled = ref(0)
    const showBottomSheet = ref<boolean>(false)
    const confirmDeleteModal = ref<boolean>(false)
    const isEditClaim = ref<boolean>(false)
    const selectedAssign = ref({} as AssignClaim)
    const selectedClaim = ref({} as any)

    const assignList = ref<Array<AssignClaim>>([])
    const claimList = ref<Array<any>>([])
    const assignedCustomersList = ref<any>([
      {
        name: root.$t('common.all'),
        id: null
      }
    ])
    const assignGroupCustomers = ref<Array<CustomerAssign>>([])
    const assignGroupCustomersFilter = ref<Array<CustomerAssign>>([])
    const onSearching = ref(false)
    const searchInput = ref('')
    const listSearch = ref<SearchItem[]>([])
    const selectedList = ref<SearchItem[]>([])
    const newKey = ref('')
    const loadingListSearch = ref(false)
    const categoriesList = ref<Category[]>([])
    const isCalledDataHasOrderNum = ref(false)
    const itemsHasOrderNum: Ref<Array<Item>> = ref([])
    const varietiesHasOrderNum: Ref<Array<Variety>> = ref([])
    const itemsSelected = ref<number[]>([])
    const varietiesSelected = ref<number[]>([])
    const claimGroupCustomers = ref<CustomerClaim[]>([])
    const claimGroupCustomersFilter = ref<CustomerClaim[]>([])

    const closeSearch = () => {
      setTimeout(() => {
        onSearching.value = false
      }, 300)
    }

    const getListSearch = async (input?: string): Promise<void> => {
      newKey.value += 1
      let itemAPI = `${endpoints.ITEMS}has_order_num`
      let varietyAPI = `${endpoints.VARIETIES}has_order_num`
      if (input) {
        const inputValue = convertToWidthCharacter(input.toLowerCase(), 'full')
        itemAPI = `${endpoints.ITEMS}search_with?search_input=${inputValue}`
        varietyAPI = `${endpoints.VARIETIES}search_with?search_input=${inputValue}`
      }
      listSearch.value = [...selectedList.value]
      loadingListSearch.value = true
      try {
        categoriesList.value = [
          { name: 'item', icon: 'mdi-flower', data: [] },
          { name: 'variety', icon: 'mdi-flower', data: [] }
        ]
        let responseItems = null
        let responseVarieties = null
        if (!isCalledDataHasOrderNum.value || input) {
          responseItems = await api.get(itemAPI)
          responseVarieties = await api.get(varietyAPI)
          if (!isCalledDataHasOrderNum.value) {
            isCalledDataHasOrderNum.value = true
            itemsHasOrderNum.value = toCamelCase(responseItems?.data)
            varietiesHasOrderNum.value = toCamelCase(responseVarieties?.data)
          }
        }
        const itemsList = input ? toCamelCase(responseItems?.data) : itemsHasOrderNum.value
        categoriesList.value.push()
        itemsList.forEach((item: Item) => {
          const newItem = {
            id: item.id,
            name: item.name,
            text: item.name,
            category: 'item'
          }
          let searchResult: any = null
          const isExist = selectedList.value.find(
            (e) => e.category === 'item' && e.id === newItem.id
          )
          const categoryItemList = categoriesList.value.find((e) => e.name === 'item')?.data
          const isExistItem = categoryItemList?.find((e) => e.id === newItem.id)
          if (input) {
            const inputValue = convertToWidthCharacter(input.toLowerCase(), 'full')
            searchResult = item.searchStr
              .toLowerCase()
              .match(`^${inputValue}|\\|${inputValue}`)?.input
            if (searchResult) {
              if (!isExist) {
                listSearch.value.unshift(newItem)
              }
              if (!isExistItem) {
                categoriesList.value.find((e) => e.name === 'item')?.data.unshift(newItem)
              }
            } else if (item.searchStr.toLowerCase().includes(input.toLowerCase())) {
              if (!isExist) {
                listSearch.value.push(newItem)
              }
              if (!isExistItem) {
                categoriesList.value.find((e) => e.name === 'item')?.data.push(newItem)
              }
            }
          } else {
            if (!isExist) {
              listSearch.value.push(newItem)
            }
            if (!isExistItem) {
              categoriesList.value.find((e) => e.name === 'item')?.data.push(newItem)
            }
          }
        })
        const varietiesList = input
          ? toCamelCase(responseVarieties?.data)
          : varietiesHasOrderNum.value
        varietiesList.forEach((variety: Variety) => {
          const newVariety = {
            id: variety.id,
            name: variety.name,
            text: `${variety.item.name} ${variety.name}`,
            category: 'variety'
          }
          const isExist = selectedList.value.find(
            (e) => e.category === 'variety' && e.id === newVariety.id
          )
          const categoryVarietyList = categoriesList.value.find((e) => e.name === 'variety')?.data
          const isExistVariety = categoryVarietyList?.find((e) => e.id === newVariety.id)
          let searchResult: any = null
          if (input) {
            const inputValue = convertToWidthCharacter(input.toLowerCase(), 'full')
            searchResult = variety.searchStr
              .toLowerCase()
              .match(`^${inputValue}|\\|${inputValue}`)?.input
            if (searchResult) {
              if (!isExist && searchResult) {
                listSearch.value.unshift(newVariety)
              }
              if (!isExistVariety) {
                categoriesList.value.find((e) => e.name === 'variety')?.data.unshift(newVariety)
              }
            } else if (variety.searchStr.toLowerCase().includes(input.toLowerCase())) {
              if (!isExist) {
                listSearch.value.push(newVariety)
              }
              if (!isExistVariety) {
                categoriesList.value.find((e) => e.name === 'variety')?.data.push(newVariety)
              }
            }
          } else {
            if (!isExist && searchResult) {
              listSearch.value.push(newVariety)
            }
            if (!isExistVariety) {
              categoriesList.value.find((e) => e.name === 'variety')?.data.push(newVariety)
            }
          }
        })
      } catch (e) {
        showError(e, $toast, root.$t('master.msg.get_data_failed') as string)
      } finally {
        loadingListSearch.value = false
      }
    }

    const clearSearchValue = () => {
      searchInput.value = ''
      selectedList.value = []
      getListSearch()
    }

    const updateCagoriesList = debounce(async (input: string) => {
      if (input && input !== searchInput.value) {
        searchInput.value = input
        getListSearch(input)
      }
    }, 300)

    const getNextAuctionDate = async () => {
      try {
        const data = await api.get(`${endpoints.NOSALE_DATE}/next_auction_date`)
        date.value = data.data.date
      } catch (e) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
    }

    // list group assigns by customer
    const groupAssignByCustomer = () => {
      const customerIds = [...new Set(assignList.value.map((assign: any) => assign.customer.id))]
      assignGroupCustomers.value = customerIds.map((customerId: number) => {
        const assignByCus = assignList.value.filter(
          (assign: any) => assign.customer.id === customerId
        )
        return { customer: assignByCus[0].customer, assigns: assignByCus }
      })
      // sort by code
      assignGroupCustomers.value = sortBy(assignGroupCustomers.value, [
        (asn: any) => {
          return asn.customer.code
        }
      ])
      assignGroupCustomersFilter.value = JSON.parse(JSON.stringify(assignGroupCustomers.value))
    }

    // get all assign list
    const getAssign = async () => {
      const queryData = query.buildQuery({
        auction_date: date.value,
        item_ids: itemsSelected.value.length > 0 ? itemsSelected.value.toString() : '',
        variety_ids: varietiesSelected.value.length > 0 ? varietiesSelected.value.toString() : ''
      })
      try {
        const { data } = await api.get(`${endpoints.ASSIGNMENTS}claim?${queryData}`)
        assignList.value = toCamelCase(data)
        filteredAssignList.value = assignList.value
      } catch (e: any) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }
      groupAssignByCustomer()
    }

    const groupClaimByCustomer = () => {
      const customerIds = [...new Set(claimList.value.map((claim: any) => claim.customer.id))]
      claimGroupCustomers.value = customerIds.map((customerId: number) => {
        const claimByCus = claimList.value.filter((claim: any) => claim.customer.id === customerId)
        return { customer: claimByCus[0].customer, claims: claimByCus }
      })
      // sort by code
      claimGroupCustomers.value = sortBy(claimGroupCustomers.value, [
        (asn: any) => {
          return asn.customer.code
        }
      ])
      claimGroupCustomersFilter.value = JSON.parse(JSON.stringify(claimGroupCustomers.value))
    }

    const getClaim = async () => {
      const queryData = query.buildQuery({
        auction_date: date.value,
        item_ids: itemsSelected.value.length > 0 ? itemsSelected.value.toString() : '',
        variety_ids: varietiesSelected.value.length > 0 ? varietiesSelected.value.toString() : ''
      })
      try {
        let listDone = []
        const { data } = await api.get(`${endpoints.CLAIM_REGISTER}?${queryData}`)
        claimList.value = toCamelCase(data)
        listDone = claimList.value.filter((e: any) => e.isHandle === true)
        countHandled.value = listDone.length
        countUnhandled.value = claimList.value.length - listDone.length
      } catch (e: any) {
        showError(e, $toast, root.$t('common.get_data_failed') as string)
      }

      groupClaimByCustomer()
    }
    const getAssignedCustomers = () => {
      assignedCustomersList.value = [
        {
          name: root.$t('common.all'),
          id: null
        }
      ]
      selectedCustomerId.value = null
      if (assignList.value.length > 0) {
        assignList.value.forEach((assign: AssignClaim) => {
          const customer = {
            name: assign.customer.shortName ? assign.customer.shortName : assign.customer.name,
            id: assign.customer.id
          }
          assignedCustomersList.value.push(customer)
        })
      }
    }
    const filterAssign = () => {
      if (selectedCustomerId.value === null) {
        assignGroupCustomersFilter.value = assignGroupCustomers.value
      } else {
        assignGroupCustomersFilter.value = assignGroupCustomers.value.filter(
          (assign: CustomerAssign) => assign.customer.id === selectedCustomerId.value
        )
      }
    }
    const filterClaim = () => {
      if (selectedCustomerId.value === null) {
        claimGroupCustomersFilter.value = claimGroupCustomers.value
      } else {
        claimGroupCustomersFilter.value = claimGroupCustomers.value.filter(
          (claim: any) => claim.customer.id === selectedCustomerId.value
        )
      }
    }
    const goToDate = async () => {
      await getAssign()
      await getClaim()
      getAssignedCustomers()
      // share date to pages other
      const latestSelectedDates = {
        auctionDate: date.value.toString(),
        packingDateRange: $store.state.common.latestSelectedDates.packingDateRange
          ? $store.state.common.latestSelectedDates.packingDateRange
          : [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')]
      }
      framebus.emit(frameBusEvent.DATE, latestSelectedDates)
    }
    const onClickToRegisterClaim = (assign: AssignClaim) => {
      isEditClaim.value = false
      selectedAssign.value = assign
      claimRegisterDialogModal.value = true
    }
    const onClickToHandlingClaim = (claim: any) => {
      showBottomSheet.value = true
      selectedClaim.value = claim
    }

    const reCalculate = (type: string, newClaim: any, oldClaim?: any) => {
      const indexAssign = assignList.value.findIndex(
        (assign: AssignClaim) => assign.id === newClaim.assignment
      )
      if (indexAssign > -1) {
        switch (type) {
          case 'create':
            if (assignList.value[indexAssign].claim !== null) {
              assignList.value[indexAssign].claim.actualSalesAmount += newClaim.actualSalesAmount
              assignList.value[indexAssign].claim.actualSalesStems += newClaim.actualSalesStems
              assignList.value[indexAssign].claim.claimAmount += newClaim.claimAmount
              assignList.value[indexAssign].claim.claimStems += newClaim.claimStems
            } else {
              assignList.value[indexAssign].claim = {
                actualSalesAmount: newClaim.actualSalesAmount,
                actualSalesStems: newClaim.actualSalesStems,
                claimAmount: newClaim.claimAmount,
                claimStems: newClaim.claimStems
              }
            }
            break
          case 'delete':
            assignList.value[indexAssign].claim.actualSalesAmount -= newClaim.actualSalesAmount
            assignList.value[indexAssign].claim.actualSalesStems -= newClaim.actualSalesStems
            assignList.value[indexAssign].claim.claimAmount -= newClaim.claimAmount
            assignList.value[indexAssign].claim.claimStems -= newClaim.claimStems
            break
          case 'update':
            if (oldClaim) {
              assignList.value[indexAssign].claim.actualSalesAmount -= oldClaim.actualSalesAmount
              assignList.value[indexAssign].claim.actualSalesStems -= oldClaim.actualSalesStems
              assignList.value[indexAssign].claim.claimAmount -= oldClaim.claimAmount
              assignList.value[indexAssign].claim.claimStems -= oldClaim.claimStems
              assignList.value[indexAssign].claim.actualSalesAmount += newClaim.actualSalesAmount
              assignList.value[indexAssign].claim.actualSalesStems += newClaim.actualSalesStems
              assignList.value[indexAssign].claim.claimAmount += newClaim.claimAmount
              assignList.value[indexAssign].claim.claimStems += newClaim.claimStems
            }
            break
          default:
            break
        }
      }
      filterAssign()
    }

    const generateBody = (claimBase: ClaimBase) => {
      return {
        claimDate: claimBase.claimDate,
        actualSalesStems: claimBase.actualSalesStems,
        actualSalesAmount: claimBase.actualSalesAmount,
        remark: claimBase.remark,
        claimStems: claimBase.claimStems,
        claimAmount: claimBase.claimAmount,
        customer: selectedAssign.value.customer.id,
        assignment: selectedAssign.value.id
      }
    }

    const createClaim = async (claimBase: ClaimBase) => {
      const body = generateBody(claimBase)
      try {
        const { data } = await api.post(`${endpoints.CLAIM_REGISTER}`, toSnakeCase(body))
        $toast.success(root.$t('common.msg.create_success'))
        // update in tab 2(list claims)
        claimList.value.push(toCamelCase(data))
        filterClaim()
        groupClaimByCustomer()
        reCalculate('create', toCamelCase(data))
        claimRegisterDialogModal.value = false
      } catch (e: any) {
        showError(e, $toast, root.$t('common.msg.create_failed') as string)
      }
    }
    const editClaim = async (claimBase: ClaimBase) => {
      const oldClaim = JSON.parse(JSON.stringify(selectedClaim.value))
      // update new assign into select claim
      selectedClaim.value.claimDate = claimBase.claimDate
      selectedClaim.value.actualSalesAmount = claimBase.actualSalesAmount
      selectedClaim.value.actualSalesStems = claimBase.actualSalesStems
      selectedClaim.value.claimAmount = claimBase.claimAmount
      selectedClaim.value.claimStems = claimBase.claimStems
      selectedClaim.value.remark = claimBase.remark

      const payload = toSnakeCase(generateBody(claimBase))
      try {
        await api.put(`${endpoints.CLAIM_REGISTER}${selectedClaim.value.id}`, payload)
        const index = claimList.value.indexOf(selectedClaim.value)
        reCalculate('update', selectedClaim.value, oldClaim)
        claimList.value[index] = selectedClaim.value
        filterClaim()
        $toast.success(root.$t('common.msg.update_success'))
        claimRegisterDialogModal.value = false
      } catch (e: any) {
        showError(e, $toast, root.$t('common.msg.update_failed') as string)
      }
    }
    const onCloseClaimHandlingDialog = () => {
      claimHandlingDialogModal.value = false
      showBottomSheet.value = false
    }
    const onDeleteClaim = () => {
      confirmDeleteModal.value = true
    }
    const deleteClaim = async (param: string) => {
      if (param === 'delete') {
        try {
          await api.delete(`${endpoints.CLAIM_REGISTER}${selectedClaim.value.id}`)
          const index = claimList.value.indexOf(selectedClaim.value)
          reCalculate('delete', claimList.value[index])
          claimList.value.splice(index, 1)
          filterClaim()
          showBottomSheet.value = false
          confirmDeleteModal.value = false
          $toast.success(root.$t('common.msg.delete_success'))
        } catch (e: any) {
          showError(e, $toast, root.$t('common.msg.delete_failed') as string)
        }
      } else if (param === 'cancel') {
        confirmDeleteModal.value = false
      }
    }
    const reload = async () => {
      await getAssign()
      await getClaim()
      getAssignedCustomers()
    }

    const onEditClaim = () => {
      const currentAssign: any = filteredAssignList.value.filter(
        (assign: any) => assign.id === selectedClaim.value.assignment
      )
      selectedAssign.value = currentAssign[0] ? currentAssign[0] : null
      claimRegisterDialogModal.value = true
      showBottomSheet.value = false
      isEditClaim.value = true
    }

    const filterAssignAfterSearch = async () => {
      itemsSelected.value = selectedList.value
        .filter((selected: SearchItem) => {
          return selected.category === 'item'
        })
        .map((selected: SearchItem) => {
          return selected.id
        })
      varietiesSelected.value = selectedList.value
        .filter((selected: SearchItem) => {
          return selected.category === 'variety'
        })
        .map((selected: SearchItem) => {
          return selected.id
        })
      await getAssign()
      await getClaim()
    }

    onMounted(async () => {
      if (
        !(
          $store.state.common.latestSelectedDates.auctionDate &&
          $store.state.common.latestSelectedDates.packingDateRange
        )
      )
        await getNextAuctionDate()
      await getAssign()
      await getClaim()
      getAssignedCustomers()
    })

    // search in selection customer
    watch(selectedCustomerId, () => {
      filterAssign()
      filterClaim()
    })

    watch(selectedList, () => {
      searchInput.value = ''
      filterAssignAfterSearch()
    })

    watch(onSearching, (val) => {
      searchInput.value = ''
      if (val) {
        getListSearch()
      }
    })

    return {
      auctionDateModal,
      date,
      selectedCustomerId,
      assignedCustomersList,
      activeTab,
      claimRegisterDialogModal,
      claimHandlingDialogModal,
      selectedAssign,
      assignList,
      claimList,
      filteredAssignList,
      selectedClaim,
      showBottomSheet,
      isEditClaim,
      countHandled,
      countUnhandled,
      confirmDeleteModal,
      onClickToRegisterClaim,
      onClickToHandlingClaim,
      goToDate,
      createClaim,
      getNextAuctionDate,
      onCloseClaimHandlingDialog,
      onDeleteClaim,
      deleteClaim,
      reload,
      onEditClaim,
      editClaim,
      getClaim,
      onSearching,
      selectedList,
      searchInput,
      listSearch,
      closeSearch,
      clearSearchValue,
      newKey,
      updateCagoriesList,
      loadingListSearch,
      categoriesList,
      claimGroupCustomersFilter,
      assignGroupCustomers,
      assignGroupCustomersFilter
    }
  }
})
export default ClaimRegister
